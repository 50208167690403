<template>
  <main class="create-claim">
    <pageTabHeader>
      <div class="header-left">
        <h5>Create Claim</h5>
        <caseHeader :claim="selectedClaim" :isLoading="isLoading" />
      </div>
    </pageTabHeader>
    <tabContent>
      <div class="inner-tab">
        <cancelView v-if="isCancel" />
        <template v-else>
          <div class="stepper-area">
            <backButton v-if="!backButtonHidden" @click="goBack" />
            <stepper :steps="parentSteps" :step="parentStep" />
          </div>
          <accountView v-if="parentStep === 1" />
          <reasonView v-if="parentStep === 2" />
          <detailView v-if="parentStep === 3" />
          <summaryView v-if="parentStep === 4" />
        </template>
      </div>
      <navButtons />
    </tabContent>
    <restartClaimModal />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import backButton from "@/components/back-button";
import caseHeader from "@/components/case-header";
import navButtons from "./nav-buttons";
import pageTabHeader from "@/components/page-tab-header";
import restartClaimModal from "./restart-claim-modal"
import stepper from "@/qux-common/qux22/components/molecules/stepper";
import tabContent from "@/components/tab-content";

import accountView from "./account";
import cancelView from "./cancel";
import reasonView from "./reason";
import detailView from "./detail";
import summaryView from "./summary";

export default {
  components: {
    backButton,
    caseHeader,
    navButtons,
    pageTabHeader,
    restartClaimModal,
    stepper,
    tabContent,
    accountView,
    cancelView,
    reasonView,
    detailView,
    summaryView,
  },
  data() {
    return {
      parentSteps: [
        {
          title: "Account",
        },
        {
          title: "Reason",
        },
        {
          title: "Detail",
        },
        {
          title: "Summary",
        },
      ],
    };
  },
  computed: {
    parentStep() {
      return this.currentStep && this.currentStep.parentStep;
    },
    isLoading() {
      return this.pendingCalls.includes("/claim/retrieve");
    },
    isCancel() {
      return this.step === 0;
    },
    ...mapGetters({
      backButtonHidden: "getIntakeNavigationBackButtonHidden",
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
      step: "getIntakeNavigationStep",
      steps: "getIntakeNavigationSteps",
      currentStep: "getIntakeNavigationCurrentStep",
    }),
  },
  methods: {
    goBack() {
      this.$store.dispatch("intakeNavigationBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.create-claim {
  height: calc(100% - #{$page-tab-height} + 16px);
  padding-bottom: 66px;
}

.header-left {
  display: flex;
  align-content: center;
  gap: $qvo-spacing-16;
}

.inner-tab {
  padding: 40px;
}

.stepper-area {
  display: flex;
  gap: $qvo-spacing-8;
  margin-top: -$qvo-spacing-16;
  .back-button {
    margin-top: $qvo-spacing-16;
  }
  .stepper {
    width: 100%;
  }
}

.nav-buttons {
  position: fixed;
  bottom: 0;
  z-index: 10;
  transition: right 200ms;
}
</style>