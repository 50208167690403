<template>
  <section class="transactions-data-table">
    <div class="filters">
      <textInput
        v-model="search"
        class="search"
        prependInnerIcon="mdi-magnify"
        placeholder="Search"
      />
      <dateRangeSelection
        :fromDate.sync="newFromDate"
        :toDate.sync="newToDate"
        fromMin="2012-01-01"
        :fromMax="newToDate"
        :toMin="newFromDate"
        :toMax="today"
      />
    </div>
    <dataTable
      :headers="headers"
      :items="isLoading ? [] : transactionsList"
      item-key="TransactionId"
      :itemsPerPage="8"
      show-select
      :singleSelect="singleSelect"
      :loading="isLoading"
      :search="search"
      :value="selectedTransactions"
      @input="setSelectedTransactions"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <template v-if="isDispute && item.IsReopenRequestInProgress">
          <tooltip
            text="A request to reopen this dispute is already in progress."
          />
        </template>
        <div v-else class="data-table-select">
          <checkbox
            v-if="!singleSelect"
            :input-value="isSelected"
            :value="isSelected"
            @click.stop
            @change="select($event)"
          />
          <radio
            v-if="singleSelect"
            :value="item.TransactionId"
            @click.stop
            @change="select(true)"
          />
        </div>
      </template>
      <template #item.Description="{ item }">
        {{ item.Description }}
        <tag
          small
          v-if="
            item.AuthorizationStatus === 'Pending' &&
            item.DebitCredit === 'Debit'
          "
          class="pending"
        >
          Pending
        </tag>
        <tag
          small
          v-if="
            item.AuthorizationStatus === 'Pending' &&
            item.DebitCredit === 'Credit'
          "
          class="pending"
        >
          Pending Credit
        </tag>
        <tag
          small
          v-if="
            item.AuthorizationStatus === 'Posted' &&
            item.DebitCredit === 'Credit'
          "
          class="pending"
        >
          Credit
        </tag>
      </template>
      <template #item.Date="{ item }">
        {{ formatDate(item.Date) }}
      </template>
      <template #item.PostingDate="{ item }">
        {{ formatDate(item.PostingDate) }}
      </template>
      <template #item.Amount="{ item }">
        {{ formatAmount(item.Amount) }}
      </template>
      <template #item.DisputedAmount="{ item }">
        {{ formatAmount(item.DisputedAmount) }}
      </template>
    </dataTable>
  </section>
</template>

<script>
/**
 * @fileoverview Transactions data table component
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { formatAmount, formatDate } from "@/util/format";

import checkbox from "@/qux-common/qux22/components/molecules/checkbox";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import dateRangeSelection from "@/components/date-range-selection";
import radio from "@/qux-common/qux22/components/molecules/radio";
import tag from "@/qux-common/qux22/components/molecules/tag";
import textInput from "@/qux-common/qux22/components/molecules/input";
import tooltip from "@/components/tooltip";

dayjs.extend(localizedFormat);

export default {
  components: {
    checkbox,
    dataTable,
    dateRangeSelection,
    radio,
    tag,
    textInput,
    tooltip,
  },
  props: {
    fromDate: {
      type: String,
      default: dayjs().subtract(12, "months").format("YYYY-MM-DD"),
    },
    toDate: {
      type: String,
      default: dayjs().format("YYYY-MM-DD"),
    },
    amount: {
      type: String,
      default: "",
    },
    sortBy: {
      type: String,
      default: "Date",
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
    transactionsList: {
      type: Array,
      required: true,
    },
    selectedTransactions: {
      type: Array,
      required: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    isDispute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newFromDate: this.fromDate,
      newToDate: this.toDate,
      search: undefined,
      sortDescItems: [
        {
          text: "Descending",
          value: "descending",
          icon: "mdi-sort-descending",
        },
        { text: "Ascending", value: "ascending", icon: "mdi-sort-ascending" },
      ],
    };
  },
  computed: {
    headers() {
      return this.isDispute
        ? [
            {
              text: "Dispute Status",
              align: "start",
              value: "DisputeStatus",
            },
            {
              text: "Merchant Name",
              align: "start",
              value: "Description",
            },
            {
              text: "Disputed Amount",
              align: "end",
              value: "DisputedAmount",
            },
            {
              text: "Dispute ID",
              align: "start",
              value: "DisputeId",
            },
            {
              text: "Transaction Date",
              align: "start",
              value: "PostingDate",
            },
          ]
        : [
            {
              text: "Merchant Name",
              align: "start",
              value: "Description",
            },
            {
              text: "Date",
              align: "start",
              value: "Date",
            },
            {
              text: "Card #",
              align: "start",
              value: "CardNumberEndingIn",
            },
            {
              text: "Amount",
              align: "end",
              value: "Amount",
            },
          ];
    },
    isLoading() {
      return (
        this.pendingCalls.includes("/claim/intake/retrieveTransactions") ||
        this.pendingCalls.includes("/claim/intake/retrieveSimilarTransactions")
      );
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
      selectedOtherAccount: "getSelectedOtherAccount",
      similarSelectedTransactions: "getSimilarSelectedTransactions",
    }),
  },
  methods: {
    retrieveTransactions() {
      const SearchStartDate = this.newFromDate || this.fromDate;
      const SearchEndDate = this.newToDate || this.toDate;
      this.$emit("retrieveTransactions", {
        ClaimId: this.selectedClaim.data.ClaimId,
        SearchStartDate: SearchStartDate.replaceAll("-", ""),
        SearchEndDate: SearchEndDate.replaceAll("-", ""),
      });
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    setSelectedTransactions(selected) {
      const filteredTransactions = selected.filter(
        (transaction) =>
          !(this.isDispute && transaction.IsReopenRequestInProgress)
      );
      this.$emit("setSelectedTransactions", filteredTransactions);
    },
  },
  watch: {
    selected() {
      this.setSelectedTransactions();
    },
    selectedOtherAccount() {
      if (this.selectedOtherAccount) {
        this.retrieveTransactions();
      }
    },
    newFromDate() {
      this.retrieveTransactions();
    },
    newToDate() {
      this.retrieveTransactions();
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions-data-table {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-16;
}
.filters {
  display: flex;
  gap: $qvo-spacing-24;
}
.search {
  max-width: 446px;
}
</style>
