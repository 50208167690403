<template>
  <div class="date-input">
    <dateInput
      :required="!isOptional"
      :rules="isOptional ? [checkDate] : [rules, checkDate]"
      :max="maxDate"
      :disabled="disabled"
      :value="formatForDatePicker"
      @input="setAnswer($event)"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { formatDate } from "@/util/format";

import dateInput from "@/components/date-input";

dayjs.extend(isSameOrBefore);

// question id's where only past dates shouldn't be selectable
const onlyPast = ["OverrideContactDate"];

export default {
  components: {
    dateInput,
  },
  data() {
    return {
      dateMenu: false,
    };
  },
  props: {
    questionId: {
      type: String,
    },
    value: {
      type: String,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: (v) => !!v || "Value is required",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDateForAnswer(date) {
      return formatDate(date);
    },
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      this.$emit("setAnswer", this.formatDateForAnswer(answer));
    },
    checkDate(date) {
      if (!this.maxDate) return true;
      return dayjs(date).isSameOrBefore(dayjs(this.maxDate))
        ? true
        : "Not a valid date";
    },
  },
  computed: {
    formatForDatePicker() {
      return this.value ? dayjs(this.value).format("YYYY-MM-DD") : null;
    },
    maxDate() {
      if (onlyPast.includes(this.questionId)) {
        const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        return yesterday;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-input {
  width: fit-content;
}
</style>