<template>
  <section class="transactions-table">
    <h6>Disputed Transactions</h6>
    <dataTable
      :headers="visibleColumns"
      :items="isLoading ? [] : selectedClaim && selectedClaim.transactions"
      itemKey="TransactionId"
      :itemsPerPage="8"
      :loading="isLoading"
      :columnFiltering="!isExternalUser && columns"
      @toggleColumn="toggleColumn"
    >
      <template #item.TransactionDate="{ item }">
        {{ formatDate(item.TransactionDate) }}
      </template>
      <template #item.PostingDate="{ item }">
        {{ formatDate(item.PostingDate) }}
      </template>
      <template #item.TransactionAmount="{ item }">
        {{ formatAmount(item.TransactionAmount) }}
      </template>
      <template #item.DisputedAmount="{ item }">
        {{ formatAmount(item.DisputedAmount) }}
      </template>
      <template #item.menu="{ item }">
        <disputedTransactionsMenu
          :claimId="selectedClaim.data.ClaimId"
          :item="item"
        />
      </template>
    </dataTable>
  </section>
</template>
  
  <script>
/**
 * @fileoverview Transactions data table component for claims
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import disputedTransactionsMenu from "@/components/disputed-transactions-menu";

export default {
  components: { dataTable, disputedTransactionsMenu },
  data() {
    return {
      columns: [
        {
          text: "ID",
          value: "DisputeId",
          display: true,
        },
        {
          text: "Description",
          value: "Description",
          display: true,
        },
        {
          text: "Transaction Date",
          value: "TransactionDate",
          display: true,
        },
        {
          text: "Posting Date",
          value: "PostingDate",
          display: true,
        },
        {
          text: "Transaction Amount",
          value: "TransactionAmount",
          display: true,
        },
        {
          text: "Disputed Amount",
          value: "DisputedAmount",
          display: true,
        },
        {
          text: "Status",
          value: "DisputeStatus",
          display: true,
        },
        // TODO: The value in the following fields doesn't exist in the API yet, so these may need to be adjusted
        {
          text: "Merchant Category Code",
          value: "MerchantCategoryCode",
          display: false,
        },
        {
          text: "Transaction Type",
          value: "TransactionType",
          display: false,
        },
        {
          text: "Acquirer Network",
          value: "AcquirerNetwork",
          display: false,
        },
        {
          text: "Remaining Recovery Amount",
          value: "RemainingRecoveryAmount",
          display: false,
        },
        {
          text: "Date Chargeback Rights Expire",
          value: "DateChargebackRightsExpire",
          display: false,
        },
        {
          text: "NACHA Return Deadline",
          value: "NACHAReturnDeadline",
          display: false,
        },
        {
          text: "Chargeback Rights Ind",
          value: "ChargebackRightsInd",
          display: false,
        },
        {
          text: "Selected Recovery Option",
          value: "SelectedRecoveryOption",
          display: false,
        },
        {
          text: "Deny Reason",
          value: "DenyReason",
          display: false,
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/transactions/list");
    },
    visibleColumns() {
      const visible = this.columns.filter((column) => column.display);
      return [
        ...visible,
        {
          align: "end",
          value: "menu",
          sortable: false,
        },
      ];
    },
    caseInView() {
      return (
        this.$route.path ===
        `/case-view/${this.selectedClaim && this.selectedClaim.data && this.selectedClaim.data.ClaimId}`
      );
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
      isExternalUser: "getIsExternalUser",
      recoveryDetails: "getRecoveryDetails",
    }),
  },
  methods: {
    retrieveTransactions() { 
      if (!(this.selectedClaim && this.selectedClaim.data && this.selectedClaim.data.ClaimId)) return;
      this.$store.dispatch("retrieveTransactionsList", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    toggleColumn(column) {
      const targetColumn = this.columns.find(
        (columnToUpdate) => columnToUpdate.value === column.value
      );
      if (targetColumn) targetColumn.display = !targetColumn.display;
    },
  },
  mounted() {
    if (!(this.selectedClaim && this.selectedClaim.transactions) && this.caseInView) {
      this.retrieveTransactions();
    }
  },
  watch: {
    selectedClaim(claim) {
      if (!claim.transactions && this.caseInView) this.retrieveTransactions();
    },
  },
};
</script>
  
<style lang="scss" scoped>
h6 {
  margin-bottom: $qvo-spacing-24;
}
</style>