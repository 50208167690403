<template>
  <div>
    <approval
      v-if="taskLabel === 'Approval'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <executeAccounting
      v-else-if="taskLabel === 'Execute Accounting'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <finalInvestigation
      v-else-if="taskLabel === 'Final Investigation'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <initialInvestigation
      v-else-if="taskLabel === 'Initial Investigation'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <notification
      v-else-if="taskLabel === 'Notification'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <readInboundCorrespondence
      v-else-if="taskLabel === 'Read Inbound Correspondence'"
      :actions="actions"
      :claimId="claimId"
      :tasks="tasks"
      :isLoading="isLoading"
    />
    <p v-else>Task type not currently supported</p>
  </div>
</template>
      
  <script>
import { mapGetters } from "vuex";

import approval from "./approval";
import executeAccounting from "./execute-accounting";
import finalInvestigation from "./final-investigation";
import initialInvestigation from "./initial-investigation";
import notification from "./notification";
import readInboundCorrespondence from "./read-inbound-correspondence";

export default {
  components: {
    approval,
    executeAccounting,
    finalInvestigation,
    initialInvestigation,
    notification,
    readInboundCorrespondence,
  },
  props: {
    claimId: {
      type: String,
    },
    taskLabel: {
      type: String,
    },
  },
  computed: {
    actions() {
      return (
        (this.taskDetailsList[this.claimId] &&
          this.taskDetailsList[this.claimId][this.taskLabel] &&
          this.taskDetailsList[this.claimId][
            this.taskLabel
          ].AvailableActionList.map((action) => action.Value)) ||
        []
      );
    },
    tasks() {
      return (
        (this.taskDetailsList[this.claimId] &&
          this.taskDetailsList[this.claimId][this.taskLabel] &&
          this.taskDetailsList[this.claimId][this.taskLabel].TaskDetailList) ||
        undefined
      );
    },
    isLoading() {
      return this.pendingCalls.includes("/task/details/list");
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
      taskDetailsList: "getTaskDetailsList",
    }),
  },
  methods: {
    retrieveTaskDetailsList() {
      this.$store.dispatch("retrieveTaskDetailsList", {
        ClaimId: this.claimId,
        TaskLabel: this.taskLabel,
      });
    },
  },
  watch: {
    claimId() {
      if (!this.tasks) this.retrieveTaskDetailsList();
    },
    taskLabel() {
      if (!this.tasks) this.retrieveTaskDetailsList();
    },
  },
  mounted() {
    this.retrieveTaskDetailsList();
  },
};
</script>
      
<style lang="scss" scoped>
</style>