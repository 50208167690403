<template>
  <disputeDetailsWrapper :claimId="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId">
    <stepper :steps="stages" :step="currentStage" />
    <div
      v-if="selectedClaim && selectedClaim.data && selectedClaim.data.AdditionalInfoRequested"
      class="additional-info-required"
    >
      <div class="title-box">
        <div class="title-row">
          <v-icon size="48" class="alert-icon">mdi-alert-outline</v-icon>
          <h5>Additional Details Needed</h5>
        </div>

        <p class="subtitle-1">
          The following information is needed from you. Please review and
          respond by uploading any necessary documents and/or sending a message
          with the requested information.
        </p>
      </div>
      <div class="requirements-row">
        <div class="requirements-list">
          <ol>
            <li
              v-for="info in selectedClaim.data.AdditionalInfoRequested"
              :key="info.InformationRequested"
            >
              {{ info.InformationRequested }}
            </li>
          </ol>
        </div>
        <div class="buttons">
          <qvoButton
            @click="openSendMessageModal"
            leftIcon="mdi-email-outline"
            class="send-message"
            >Send Message</qvoButton
          >
          <h5>and/or</h5>
          <uploadModal
            label="Document"
            leftIcon="mdi-upload-outline"
            @attachFile="addAttachments"
          />
        </div>
      </div>
    </div>
    <transactionsTable />
    <sendMessageModal
      :isOpen="sendMessageModal.isOpen"
      @closeModal="sendMessageModal.isOpen = false"
    />
  </disputeDetailsWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import disputeDetailsWrapper from "@/views/dispute-details";
import sendMessageModal from "@/components/send-message-modal";
import stepper from "@/qux-common/qux22/components/molecules/stepper";
import transactionsTable from "./transactions-table";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import uploadModal from "@/views/create-claim/detail/required-docs-upload-modal";

export default {
  components: {
    disputeDetailsWrapper,
    sendMessageModal,
    stepper,
    transactionsTable,
    qvoButton,
    uploadModal,
  },
  data() {
    return {
      sendMessageModal: {
        isOpen: false,
      },
      showCaseNotes: false,
      viewCaseNotesAction: "View case notes",
    };
  },
  computed: {
    currentStage() {
      const current =
        this.selectedClaim &&
        this.selectedClaim.data &&
        this.selectedClaim.data.Stages &&
        this.selectedClaim.data.Stages.findIndex(
          (stage) => stage.Status === "Current"
        ) + 1;
      // when resolved, there is no stage set as 'current', even the last stage (resolved) will be set to complete
      return (
        current ||
        (this.selectedClaim &&
          this.selectedClaim.data &&
          this.selectedClaim.data.Stages &&
          this.selectedClaim.data.Stages.length) ||
        1
      );
    },
    stages() {
      return (
        (this.selectedClaim &&
          this.selectedClaim.data &&
          this.selectedClaim.data.Stages &&
          this.selectedClaim.data.Stages.map((stage) => ({ title: stage.Name }))) ||
        []
      );
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    addAttachments({ category, file, name }) {
      // for now UI only supports the one document, if we encounter the use case for multiple we can add support
      this.$store.dispatch("addAttachments", {
        ClaimId: this.selectedClaim.data.ClaimId,
        files: [{ Category: category, file, Label: name }],
      });
    },
    openSendMessageModal() {
      this.sendMessageModal.isOpen = true;
    },
    /**
     * Calls the trigger for the selected action
     * @param {String} action - action name
     */
    triggerCaseAction(action) {
      if (action === this.viewCaseNotesAction) {
        this.toggleCaseNotes();
      }
      this.$store.dispatch("triggerCaseAction", {
        action,
        claimId: this.selectedClaim.data.ClaimId,
      });
    },
    toggleCaseNotes() {
      this.showCaseNotes = !this.showCaseNotes;
    },
  },
};
</script>

<style lang="scss" scoped>
.additional-info-required {
  display: flex;
  flex-direction: column;
  gap: 36px;
  background-color: $qvo-color-blue-100;
  padding: $qvo-spacing-24;
  border-radius: 8px;
  margin-bottom: 30px;
  .title-box {
    background-color: $qvo-color-grey-000;
    padding: 24px 48px;
    border-radius: 8px;
    box-shadow: $qvo-shadow-2;
    display: flex;
    flex-direction: column;
    gap: $qvo-spacing-16;
    .title-row {
      display: flex;
      align-items: center;
      gap: $qvo-spacing-16;
      .alert-icon {
        color: $qvo-color-yellow-100;
      }
    }
  }
  .requirements-row {
    display: flex;
    gap: 48px;
    .requirements-list {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -24px;
        border-right: 1px solid $qvo-color-grey-500;
      }
    }
    .buttons {
      margin-left: 24px;
      display: flex;
      gap: 24px;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>