<template>
  <section class="summary-questionnaire">
    <selectedTransactionsOverview class="transactions-overview" />
    <div class="questions">
      <div class="send-info">
        <p class="subtitle-2">
          Is it okay to send information to this address?
        </p>
        <p class="subtitle-2">{{ currentAddress }}</p>
        <v-form ref="sendInfoFrom" @input="setSummaryQuestionnaireFormValidity">
          <radioGroup
            :value="sendInfo"
            @input="setSummarySendInfo"
            :rules="[(v) => !!v || 'Selection is required']"
            required
          >
            <radio label="Yes" value="Yes" />
            <radio label="No" value="No" />
          </radioGroup>
        </v-form>
        <div class="update-info" v-if="sendInfo === 'No'">
          <p class="subtitle-2">Okay, where should we send information?</p>
          <v-form
            ref="channelForm"
            @input="setSummaryQuestionnaireFormValidity"
          >
            <dropdown
              :value="channel"
              @input="setSummaryChannel"
              :items="['Email', 'Mail']"
              :rules="[(v) => !!v || 'Selection is required']"
            />
            <div v-if="channel === 'Email'">
              <qvoInput
                :value="emailAdress"
                @input="setSummaryEmailAddress"
                label="Email Address"
                :rules="[(v) => !!v || 'Value is required']"
              />
            </div>
            <div class="mail" v-if="channel === 'Mail'">
              <qvoInput
                label="Address Line 1"
                v-model="newAddress.Line1"
                :rules="[(v) => !!v || 'Value is required']"
              />
              <qvoInput label="Address Line 2" v-model="newAddress.Line2" />
              <qvoInput label="Address Line 3" v-model="newAddress.Line3" />
              <div class="city-state-zip">
                <qvoInput
                  label="City"
                  v-model="newAddress.City"
                  :rules="[(v) => !!v || 'Value is required']"
                />
                <qvoInput
                  label="State"
                  v-model="newAddress.State"
                  :rules="[(v) => !!v || 'Value is required']"
                />
                <qvoInput
                  label="Zip"
                  v-model="newAddress.Zip"
                  :rules="[(v) => !!v || 'Value is required']"
                />
              </div>
              <qvoInput
                label="Country"
                v-model="newAddress.Country"
                :rules="[(v) => !!v || 'Value is required']"
              />
            </div>
          </v-form>
        </div>
      </div>
      <v-form ref="answerForm" @input="setSummaryQuestionnaireFormValidity">
        <div
          class="questions"
          v-for="question in questionList"
          :key="question.Identifier"
        >
          <inputControls
            :question="question"
            :answers="answers"
            @setAnswer="setSummaryAnswer"
            @unsetAnswer="unsetSummaryAnswer"
          />
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import inputControls from "../input-controls";
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
import selectedTransactionsOverview from "@/components/selected-transactions-overview";
import qvoInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    dropdown,
    inputControls,
    radio,
    radioGroup,
    selectedTransactionsOverview,
    qvoInput,
  },
  data() {
    return {
      newAddress: {
        Line1: null,
        Line2: null,
        Line3: null,
        City: null,
        State: null,
        Zip: null,
        Country: null,
      },
    };
  },
  computed: {
    questionList() {
      if (this.summary && this.summary.SummaryQuestionnaire)
        return this.summary.SummaryQuestionnaire.QuestionList;
      return [];
    },
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      summary: "getSummary",
      correspondenceChannels: "getCorrespondenceChannels",
      answers: "getSummaryAnswers",
      channel: "getSummaryChannel",
      emailAddress: "getSummaryEmailAddress",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
      sendInfo: "getSummarySendInfo",
    }),
    currentAddress() {
      if (this.summary.CorrespondenceDetails) {
        const currentChannel =
          this.summary.CorrespondenceDetails.SelectedChannel;

        if (currentChannel === "Email") return this.emailAddress;
        if (currentChannel === "Mail") return this.newAddress.Line1;
      }
      return null;
    },
  },
  methods: {
    retrieveSummary() {
      this.$store.dispatch("retrieveSummary", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    retrieveCorrespondenceChannels() {
      this.$store.dispatch("retrieveCorrespondenceChannels", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    setSummaryAnswer({ questionId, answer }) {
      this.$store.dispatch("setSummaryAnswer", {
        questionId,
        answer,
      });
    },
    unsetSummaryAnswer({ questionId }) {
      if (this.currentStep.name === "summaryQuestionnaire") {
        // since this is automatically triggered when questions aren't visible in the UI,
        // we don't want this to run when leaving this page, otherwise all answers will unset.
        this.$store.dispatch("unsetSummaryAnswer", {
          questionId,
        });
      }
    },
    setSummaryAddress(value) {
      this.$store.dispatch("setSummaryAddress", value);
    },
    setSummaryChannel(value) {
      this.$store.dispatch("setSummaryChannel", value);
    },
    setSummaryEmailAddress(value) {
      this.$store.dispatch("setSummaryEmailAddress", value);
    },
    setSummarySendInfo(value) {
      this.$store.dispatch("setSummarySendInfo", value);
    },
    setSummaryQuestionnaireFormValidity() {
      const answerForm = this.$refs.answerForm.validate();
      const sendInfoForm = this.$refs.sendInfoFrom.validate();
      const channelForm =
        this.sendInfo === "No" ? this.$refs.channelForm.validate() : true;
      const isValid = answerForm && sendInfoForm && channelForm;
      this.$store.dispatch("setSummaryQuestionnaireFormValidity", {
        value: isValid,
      });
    },
  },
  mounted() {
    if (this.currentStep.name === "summaryQuestionnaire") {
      this.retrieveSummary();
      this.retrieveCorrespondenceChannels();
    }
  },
  watch: {
    "currentStep.name"() {
      if (this.currentStep.name === "summaryQuestionnaire") {
        this.retrieveSummary();
        this.retrieveCorrespondenceChannels();
      }
    },
    // initalize form values once data is returned from API
    summary(summary) {
      const { CorrespondenceDetails } = summary;
      this.$store.dispatch(
        "setSummaryChannel",
        CorrespondenceDetails.SelectedChannel
      );
    },
    correspondenceChannels(correspondenceChannels) {
      const emailDetails = correspondenceChannels.find(
        (channel) => channel.Name === "Email"
      );
      this.$store.dispatch(
        "setSummaryEmailAddress",
        emailDetails.Address.EmailAddress
      );

      const mailDetails = correspondenceChannels.find(
        (channel) => channel.Name === "Mail"
      );
      this.newAddress = mailDetails.Address;
    },
    newAddress: {
      handler(newValue) {
        this.$store.dispatch("setSummaryAddress", newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p.subtitle-2 {
  margin-bottom: $qvo-spacing-8;
}
.summary-questionnaire {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $qvo-spacing-24;
  @media only screen and (min-width: 1200px) {
    flex-direction: row-reverse;
  }
}
.send-info {
  padding-bottom: 36px;
}
.update-info {
  margin-top: $qvo-spacing-24;
}
.mail {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  .city-state-zip {
    @media only screen and (min-width: 900px) {
      display: flex;
      gap: $qvo-spacing-24;
    }
  }
}
</style>