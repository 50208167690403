<template>
  <main class="dashboard">
    <pageTabs
      :value="activeTab"
      :items="tabItems"
      @closeTab="closeTab"
      class="page-tabs"
      :class="navPanelTransitioning && 'transition-width'"
      :style="`width: calc(100vw - ${panelWidth}px)`"
    >
      <search v-show="activeTab === '/'" />
      <createClaim v-show="activeTab.startsWith('/create-claim')" />
      <caseView v-show="activeTab.startsWith('/case-view/')" />
      <tasks v-show="activeTab.startsWith('/tasks')" />
    </pageTabs>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

import caseView from "@/views/case";
import createClaim from "@/views/create-claim";
import pageTabs from "@/qux-common/qux22/components/molecules/page-tabs";
import search from "./search";
import tasks from "./tasks";

export default {
  components: {
    caseView,
    createClaim,
    pageTabs,
    search,
    tasks,
  },
  methods: {
    closeTab(tabItem) {
      if (tabItem.to.startsWith("/create-claim/")) {
        this.$store.dispatch("cancelIntakeNav");
        return;
      }
      this.$store.dispatch("removeNavItem", tabItem.to);
    },
    setActiveTab(tab = "/") {
      this.$store.dispatch("setActiveTab", tab);
      if (tab.startsWith("/case-view/")) {
        const id = tab.split("/")[2];
        this.$store.dispatch("setSelectedClaim", id);
      }
    },
  },
  computed: {
    tabItems() {
      const openTabs = this.navList.map(({ label, href, hideCloseButton }) => {
        return {
          name: label,
          to: href,
          hideCloseButton,
        };
      });
      return openTabs;
    },
    ...mapGetters({
      activeTab: "getActiveTab",
      navList: "getNavList",
      navPanelTransitioning: "getNavPanelTransitioning",
      panelWidth: "getTotalPanelWidth",
    }),
  },
  mounted() {
    this.setActiveTab(this.$route.path);
  },
  watch: {
    "$route.path"(path) {
      this.setActiveTab(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-tabs {
  box-shadow: unset;
  height: calc(100vh - #{$header-height});
  &.transition-width {
    transition: width 200ms;
  }
  &::v-deep {
    .v-tabs-items {
      height: calc(100% - #{$page-tab-height});
    }
    .v-tabs-bar {
      z-index: 10;
      .v-tab,
      .v-tab:not(.v-tab--active) {
        .left-icon {
          color: $qvo-color-yellow-100;
        }
      }
    }
  }
}
</style>