<template>
  <div class="date-input" ref="dateInputWrapper">
    <textInput
      v-bind="$attrs"
      v-on="$listeners"
      ref="dateInput"
      @focus="showDatePicker"
      prependInnerIcon="mdi-calendar-blank-outline"
    />
  </div>
</template>
    
  <script>
/**
 * @fileoverview Date input component
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  name: "DateInput",
  components: {
    textInput,
  },
  props: {
    max: {
      type: String,
    },
    min: {
      type: String,
    },
  },
  data() {
    return {
      fpInstance: null,
    };
  },
  computed: {
    flatpickrConfig() {
      return {
        altFormat: "m/d/Y",
        altInput: true,
        dateFormat: "Y-m-d",
        minDate: this.min,
        maxDate: this.max,
        position: "auto left",
      };
    },
  },
  methods: {
    initFlatpickr() {
      this.fpInstance = flatpickr(
        this.$refs.dateInput.$refs.input.$refs.input,
        {
          ...this.flatpickrConfig,
          positionElement: this.$refs.dateInputWrapper,
        }
      );
    },
    updateFlatpickr(newConfig) {
      if (this.fpInstance) {
        this.fpInstance.destroy();
      }
      this.fpInstance = flatpickr(
        this.$refs.dateInput.$refs.input.$refs.input,
        {
          ...newConfig,
          positionElement: this.$refs.dateInputWrapper,
        }
      );
    },
  },
  mounted() {
    this.initFlatpickr();
  },
  watch: {
    flatpickrConfig: {
      deep: true,
      handler(newConfig) {
        this.updateFlatpickr(newConfig);
      },
    },
  },
  beforeDestroy() {
    if (this.fpInstance) {
      this.fpInstance.destroy();
    }
  },
};
</script>
    
<style lang="scss" scoped>
.date-input {
  &::v-deep {
    .flatpickr-input {
      width: 13ch;
    }
  }
}
</style>