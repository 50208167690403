<template>
  <div class="date-selection">
    <dateInput
      :value="fromDate"
      :min="fromMin"
      :max="fromMax"
      @input="$emit('update:fromDate', $event)"
    />
    <dateInput
      :value="toDate"
      :min="toMin"
      :max="toMax"
      @input="$emit('update:toDate', $event)"
    />
  </div>
</template>
  
<script>
/**
 * @fileoverview Date range selection component
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import dateInput from "@/components/date-input";

export default {
  name: "DateRangeSelection",
  components: {
    dateInput,
  },
  props: {
    fromDate: {
      type: String,
    },
    fromMin: {
      type: String,
    },
    fromMax: {
      type: String,
    },
    toDate: {
      type: String,
    },
    toMin: {
      type: String,
    },
    toMax: {
      type: String,
    },
  },
};
</script>
  
<style lang="scss" scoped>
.date-selection {
  display: flex;
  div:first-child {
    &::v-deep {
      .v-input__slot {
        border-radius: $qvo-spacing-8 0 0 $qvo-spacing-8;
        fieldset {
          border-right: 0;
        }
      }
      .v-input--is-focused .v-input__slot fieldset {
        border-right: 2px solid currentColor;
      }
    }
  }
  div:last-child {
    display: flex;
    &:before {
      content: "";
      border-left: 1px solid $qvo-color-grey-300;
    }
    &::v-deep {
      .v-input__slot {
        border-radius: 0 $qvo-spacing-8 $qvo-spacing-8 0;
        fieldset {
          border-left: 0;
        }
      }
      .v-input--is-focused .v-input__slot fieldset {
        border-left: 2px solid currentColor;
      }
    }
  }
}
</style>